//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'PageHeader',
  props: {
    title: {
      type: String,
      required: true
    },
    route: {
      type: [Boolean, Array, Object],
      required: false,
      default: false
    },
    back: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    goBack: function goBack() {
      this.$router.go(-1);
    }
  }
};