import _objectSpread from "/workspace/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapActions, mapGetters } from 'vuex';

var Feedbacks = function Feedbacks() {
  return import('@/views/lab/series/_seriesId/feedbacks');
};

var Levels = function Levels() {
  return import('@/views/lab/series/_seriesId/level/index');
};

export default {
  name: 'SeriesId',
  data: function data() {
    return {
      loading: true,
      currentPage: this.$route.params.currentPage,
      pages: [{
        label: this.$t('#OpenSeries #SwitchButtonTitle Feedbacks'),
        value: 'feedbacks'
      }, {
        label: this.$t('#OpenSeries #SwitchButtonTitle Levels'),
        value: 'levels'
      }]
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters({
    series: 'series/selectedSeries'
  })), {}, {
    pageLayout: function pageLayout() {
      return this.currentPage === 'feedbacks' ? Feedbacks : Levels;
    }
  }),
  watch: {
    currentPage: function currentPage() {
      this.$router.push({
        name: 'lab-open-series',
        params: {
          currentPage: this.currentPage
        }
      });
    }
  },
  beforeMount: function beforeMount() {
    var _this = this;

    this.$store.dispatch('series/findById', this.$route.params.seriesId).then(function () {
      _this.loading = false;
    });
  },
  methods: _objectSpread({}, mapActions('series', ['findById']))
};