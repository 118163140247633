//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import FirebaseService from '@/services/firebase.service';
export default {
  name: 'LogoutButton',
  methods: {
    logout: function logout() {
      this.$swal({
        icon: 'warning',
        title: this.$t('#SweetAlertTitle Sign Out'),
        html: this.$t('#SweetAlertDescription Sign Out'),
        showCancelButton: true,
        cancelButtonText: this.$t('#SweetAlertButton No'),
        confirmButtonText: this.$t('#SweetAlertButton Yes'),
        customClass: {
          confirmButton: 'btn btn-secondary color-secondary',
          cancelButton: 'btn btn-danger ml-1 font-color-white'
        }
      }).then(function (result) {
        if (result.isConfirmed) {
          FirebaseService.signOut();
        }
      });
    }
  }
};