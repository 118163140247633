//
//
//
//
//
//
//
//
//
export default {
  name: 'PageContainerWithScroll',
  watch: {
    $route: function $route() {
      this.$refs.scrollbar.$el.scrollTop = 0;
    }
  }
};