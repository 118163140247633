import "core-js/modules/es.array.find-index.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'SwitchButton',
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: undefined,
      required: true
    }
  },
  data: function data() {
    return {
      model: this.value
    };
  },
  computed: {
    modelIndex: function modelIndex() {
      var _this = this;

      return this.options.findIndex(function (option) {
        return option.value === _this.model;
      });
    },
    next: function next() {
      if (this.options.length === this.modelIndex + 1) {
        return this.options[0].value;
      }

      return this.options[this.modelIndex + 1].value;
    }
  },
  methods: {
    handleInput: function handleInput() {
      this.model = this.next;
      this.$emit('input', this.model);
    }
  }
};